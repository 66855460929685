import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import BasePage from "./pages/HomePage/BasePage";
import RecipesPage from "./pages/RecipesPage/RecipesPage";
import RecipePage from "./pages/RecipesPage/RecipePage";
import BlogPage from "./pages/BlogPage/BlogPage";
import AboutSingularBlogPage from "./pages/BlogPage/AboutSingularBlogPage";
import PointsSalePage from "./pages/PointsSalePage/PointsSalePage";
import ShopPage from "./pages/ShopPage/ShopPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import {CheckoutaPage} from "./pages/CheckoutPage/CheckoutPage";
import CheckoutbPage from "./pages/CheckoutPage/CheckoutbPage";
import CheckoutcPage from "./pages/CheckoutPage/CheckoutcPage";
import CheckoutFastPage from "./pages/CheckoutPage/CheckoutFastPage";
import ModaconscientePage from "./pages/ModaconscientePage/ModaconscientePage";
import AcercaPage from "./pages/AcercaPage/AcercaPage";
import SocialResponsabilityPage from "./pages/AcercaPage/SocialResponsabilityPage";
import DetallescuentaPage from "./pages/PerfilPage/DetallescuentaPage";
import MetodospagoPage from "./pages/PerfilPage/MetodospagoPage";
import DireccionesPage from "./pages/PerfilPage/DireccionesPage";
import NuevaDireccionPage from "./pages/PerfilPage/NuevaDireccionPage";
import FeedbackaPage from "./pages/FeedbackPage/FeedbackaPage";
import TarjetaaPage from "./pages/TarjetaregaloPage/TarjetaaPage";
import TarjetabPage from "./pages/TarjetaregaloPage/TarjetabPage";
import TarjetacPage from "./pages/TarjetaregaloPage/TarjetacPage";
import TarjetadPage from "./pages/TarjetaregaloPage/TarjetadPage";
import ContactoPage from "./pages/ContactoPage/ContactoPage";
import PreguntasPage from "./pages/PqrsPage/PreguntasPage";
import EnviosPage from "./pages/PqrsPage/EnviosPage";
import PoliticacomprasPage from "./pages/PqrsPage/PoliticacomprasPage";
import AvisoprivacidadPage from "./pages/PqrsPage/AvisoprivacidadPage";
import PoliticadatosPage from "./pages/PqrsPage/PoliticadatosPage";
import PoliticacambiosPage from "./pages/PqrsPage/PoliticacambiosPage";
import PoliticaRetractoPage from "./pages/PqrsPage/PoliticaRetractoPage";
import PoliticaReversionPagoPage from "./pages/PqrsPage/PoliticaReversionPagoPage";
import SeguridadPage from "./pages/PqrsPage/SeguridadPage";
import DerechosDeberesPage from "./pages/PqrsPage/DerechosDeberesPage";
import ManualPage from "./pages/PqrsPage/ManualPage";
import ProgramaTransparenciaPage from "./pages/PqrsPage/ProgramaTransparenciaPage";
import FormulariocontactoPage from "./pages/PqrsPage/FormulariocontactoPage";
import FeedbacksliderPage from "./pages/FeedbackPage/FeedbacksliderPage";
import ColeccionPage from "./pages/ShopPage/ColeccionPage";
import CombinacionesPage from "./pages/ShopPage/CombinacionesPage";
import CombinacionPage from "./pages/ShopPage/CombinacionPage";
import OfertasPage from "./pages/ShopPage/OfertasPage";
import FindsizePage from "./pages/FindsizePage/FindsizePage";
import PedidosPage from "./pages/PerfilPage/PedidosPage";
import FavoritosPage from "./pages/PerfilPage/FavoritosPage";
import NftPage from "./pages/NftPage/NftPage";
import NewsletterPage from "./pages/NewsPage/NewsletterPage";
import CompraPage from "./pages/FeedbackPage/CompraPage";
import BuscadorComponent from "./components/BuscadorComponent";
import WomenPage from "./pages/ShidoWomenPage/WomenPage";
import PagoExitosoPage from "./pages/CheckoutPage/PagoExitosoPage";
import LoginPage from "./pages/UserPage/LoginPage";
import RegisterPage from "./pages/UserPage/RegisterPage";
import PreorderPage from "./pages/HomePage/PreorderPage";
import ResetPassPage from "./pages/UserPage/ResetPassPage";
import Error404Page from "./pages/404Page/404Page";
import SatisfactionSurveyPage from "./pages/SatisfactionSurveyPage/SatisfactionSurveyPage";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import { getEnvVariables } from "./helpers";
import { getSeo } from "./api/productApi";
import TerminosPage from "./pages/PqrsPage/TerminosPage";

export function RoutesList() {

    const { GTM ,IMAGES_URL_PRODUCTS} = getEnvVariables();

    

    const scrolling = (top = true) => {
        if (!top) scrollTop();
    }

    const scrollTop = () => { window.scroll(0, 0); }

    useEffect(() => {
        // TagManager.initialize({ gtmId: GTM });
    }, []);

    const location = useLocation();
    useEffect(() => {
        const metaimage= document.querySelector('meta[property="og:image"]');
        const imageUrlWithHost = `https://${window.location.host}/img/logo-cervalle.png`;
        const urlmeta = document.querySelector('meta[property="og:url"]');
        metaimage.setAttribute('content',imageUrlWithHost);
        urlmeta.setAttribute('content', `https://${window.location.host}${location.pathname}`);

         getSeo(location.pathname).then((res) => {
            //console.log("SEO Retrieve", res);
            if (res) {
                if (res.length > 0) {
                    const metaDesc = document.querySelector('meta[name="description"]');
                    const metaDescog = document.querySelector('meta[property="og:description"]');
                    const metatitle=document.querySelector('meta[property="og:title"]');
                   
                    const metaKeywords = document.querySelector('meta[name="keywords"]');
                    document.title = res[0].titulo;
                    metatitle.setAttribute('content', res[0].titulo);
                    metaDesc.setAttribute('content', res[0].descripcion);
                    metaimage.setAttribute('content',IMAGES_URL_PRODUCTS+res[0].imagen);
                    metaDescog.setAttribute('content', res[0].descripcion);
                    metaKeywords.setAttribute('content', res[0].keywords);
                  
                }
            }

        });
        
        window.scrollTo(0, 0);
        // TagManager.dataLayer({
        //     dataLayer: {
        //         event: "pageview",
        //         page: location.pathname,
        //     }
        // });
    }, [location]);

      
    

    return (
        <>
            <Routes>
            <Route onClick={() => scrollTop()} path="/" element={<HomePage />} />
                    <Route onClick={() => scrollTop()} path="/login" element={<LoginPage />} />
                    <Route onClick={() => scrollTop()} path="/register" element={<RegisterPage />} />
                    <Route onClick={() => scrollTop()} path="/reset-password" element={<ResetPassPage />} />
                    <Route onClick={() => scrollTop()} path="/base" element={<BasePage />} />
                    <Route onClick={() => scrollTop()} path="/preorder" element={<PreorderPage />} />
                    <Route onClick={() => scrollTop()} path="/recetas" element={<RecipesPage />} />
                    <Route onClick={() => scrollTop()} path="/recetas/:category" element={<RecipesPage />} />
                    <Route onClick={() => scrollTop()} path="/receta/:id" element={<RecipePage />} />
                    <Route onClick={() => scrollTop()} path="/blogs" element={<BlogPage />} />
                    <Route onClick={() => scrollTop()} path="/blog/:id" element={<AboutSingularBlogPage />} />
                    <Route onClick={() => scrollTop()} path="/busqueda" element={<SearchPage />} />
                    <Route onClick={() => scrollTop()} path="/puntos-venta" element={<PointsSalePage />} />
                    <Route onClick={() => scrollTop()} path="/shop" element={<ShopPage />} >
                        <Route onClick={() => scrollTop()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => scrollTop()} path=":subcategorias/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => scrollTop()} path="/linea" element={<ShopPage />} >
                        <Route onClick={() => scrollTop()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => scrollTop()} path=":subcategorias/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => scrollTop()} path="/offers" element={<ShopPage />} >
                        <Route onClick={() => scrollTop()} path=":categoria" element={<ShopPage />} >
                            <Route onClick={() => scrollTop()} path=":subcategorias/:order" element={<ShopPage />} />
                        </Route>
                    </Route>
                    <Route onClick={() => scrollTop()} path="/combinations" element={<CombinacionesPage />} />
                    <Route onClick={() => scrollTop()} path="/combination/:id" element={<CombinacionPage />} />
                    <Route onClick={() => scrollTop()} path="/product/:plu" element={<ProductPage />} />
                    <Route onClick={() => scrollTop()} path="/find-my-size" element={<FindsizePage />} />
                    <Route onClick={() => scrollTop()} path="/checkout-a" element={<CheckoutaPage />} />
                    <Route onClick={() => scrollTop()} path="/checkout-b" element={<CheckoutbPage />} />
                    <Route onClick={() => scrollTop()} path="/checkout-c" element={<CheckoutcPage />} />
                    <Route onClick={() => scrollTop()} path="/checkout-d" element={<CheckoutFastPage />} />
                    <Route onClick={() => scrollTop()} path="/payment-success" element={<PagoExitosoPage />} />
                    <Route onClick={() => scrollTop()} path="/conscious" element={<ModaconscientePage />} />
                    <Route onClick={() => scrollTop()} path="/nosotros" element={<AcercaPage />} />
                    <Route onClick={() => scrollTop()} path="/nuestro-programa" element={<SocialResponsabilityPage />} />
                    <Route onClick={() => scrollTop()} path="/profile-details" element={<DetallescuentaPage />} />
                    <Route onClick={() => scrollTop()} path="/profile-methods" element={<MetodospagoPage />} />
                    <Route onClick={() => scrollTop()} path="/profile-address" element={<DireccionesPage />} />
                    <Route onClick={() => scrollTop()} path="/nueva-direccion" element={<NuevaDireccionPage />} />
                    <Route onClick={() => scrollTop()} path="/profile-orders" element={<PedidosPage />}/>
                    <Route onClick={() => scrollTop()} path="/profile-favs" element={<FavoritosPage />}/>
                    <Route onClick={() => scrollTop()} path="/feedback-a" element={<FeedbackaPage />} />
                    <Route onClick={() => scrollTop()} path="/feedback-slider" element={<FeedbacksliderPage />} />
                    <Route onClick={() => scrollTop()} path="/tarjeta-a" element={<TarjetaaPage />} />
                    <Route onClick={() => scrollTop()} path="/tarjeta-b" element={<TarjetabPage />} />
                    <Route onClick={() => scrollTop()} path="/tarjeta-c" element={<TarjetacPage />} />
                    <Route onClick={() => scrollTop()} path="/tarjeta-d" element={<TarjetadPage />} />
                    <Route onClick={() => scrollTop()} path="/compra-news" element={<CompraPage />} />
                    <Route onClick={() => scrollTop()} path="/contacto" element={<ContactoPage />} />
                    <Route onClick={() => scrollTop()} path="/preguntas-frecuentes" element={<PreguntasPage />} />
                    <Route onClick={() => scrollTop()} path="/envios" element={<EnviosPage />} />
                   
                    {/*<Route onClick={() => scrollTop()} path="/politicas-de-garantia" element={<PoliticacomprasPage />} />
                    <Route onClick={() => scrollTop()} path="/politicas-de-retracto" element={<PoliticaRetractoPage />} />
                    <Route onClick={() => scrollTop()} path="/politicas-de-reversion" element={<PoliticaReversionPagoPage />} />
                    <Route onClick={() => scrollTop()} path="/derechos-y-deberes" element={<DerechosDeberesPage />} />
                    <Route onClick={() => scrollTop()} path="/aviso-de-privacidad" element={<AvisoprivacidadPage />} />
                    <Route onClick={() => scrollTop()} path="/politica-de-datos" element={<PoliticadatosPage />} />
                    <Route onClick={() => scrollTop()} path="/politica-de-cambios-devoluciones" element={<PoliticacambiosPage />} />
                    <Route onClick={() => scrollTop()} path="/politica-seguridad" element={<SeguridadPage />} />
                    <Route onClick={() => scrollTop()} path="/manual" element={<ManualPage />} />*/}
                    <Route onClick={() => scrollTop()} path="/terminos-condiciones" element={<TerminosPage />} />
                    {/*<Route onClick={() => scrollTop()} path="/programa-transparencia" element={<ProgramaTransparenciaPage />} />*/}
                    <Route onClick={() => scrollTop()} path="/contactanos" element={<FormulariocontactoPage />} />
                    <Route onClick={() => scrollTop()} path="/nft" element={<NftPage />} />
                    <Route onClick={() => scrollTop()} path="/news-letter" element={<NewsletterPage />} />
                    <Route onClick={() => scrollTop()} path="/shido-women" element={<WomenPage />} />
                    <Route onClick={() => scrollTop()} path="/encuesta-satisfaccion" element={<SatisfactionSurveyPage />} />
                    <Route onClick={() => scrollTop()} path="/:slug" element={<PoliticacomprasPage />} />
                    <Route onClick={() => scrollTop()} path="*" element={<Error404Page />} />
                </Routes>
        </>
    )
}
