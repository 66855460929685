import { Fragment, useEffect } from "react";
import { NavpoliticasComponent } from "./components/NavpoliticasComponent";
import { useApi } from "../../context/apiContext";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGallery } from "../../api/galleryApi";

export default function PoliticacomprasPage() {

    const { getTextByKey, getTextBigByKey ,getPropiertyByLocale} = useApi();
    const [isActive, setIsActive] = useState(false);
    const [politica, setPolitica] = useState('');
    const [politicas, setPoliticas] = useState([])
    const params = useParams();
    const navigate = useNavigate(); 


    const handleClick =() =>{
        setIsActive(!isActive)
    }


    useEffect(() => {
        getGallery("listado-politicas").then((data) => {
            setPoliticas(data);
            const politicaexist= data.find(item => item.link === '/'+params.slug);
            if (politicaexist) {
                setPolitica(politicaexist);
            } else {
                navigate("/error/404", { replace: true }); 
            }
        });
    }, [params.slug]);
    
  return (
    <Fragment>
        <div className="container pt-3 pt-md-5 pb-5">
            <div className="row">
                <div className="row">
                    <div className="col-12 col-lg-8 ms-auto">
                        <div className="py-2 py-md-5 px-2">
                            <h1 className="primary-color carrefourMetis-bold text-start d-none d-lg-block">{getPropiertyByLocale(politica.names, 'titulo')}</h1>
                    
                            <h2 className="primary-color fw-bold text-start d-block d-lg-none source-sans-bold">{getPropiertyByLocale(politica.names, 'titulo')}</h2>
                        </div>
                    </div>
                </div>
                { politica && politicas.length ?
                <div className="d-none d-lg-block col-lg-4">
                    <NavpoliticasComponent politicas={politicas}/>
                </div>
                :<></>
                }
                <div className="col-12 col-lg-8">
                    <div className={`pe-lg-5 pe-3 text-justify texto-politicas ${isActive ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: getPropiertyByLocale(politica.names, 'descripcion')}}></div>
                </div>
                <div className="d-flex justify-content-center py-4">
                    <button className="d-block d-lg-none btn-g bb py-1 px-4" onClick={handleClick}>{getTextByKey('leer-mas')}</button>
                </div>
                <div className="d-block d-lg-none col-12">
                    <NavpoliticasComponent/>
                </div>
            </div>
        </div>
        
    </Fragment>
  )
}
